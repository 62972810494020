body,
html {
    height: 100%;
    margin: 0;
}

.centered-content {
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    align-items: center;
    height: calc(100% - 60px);
    /* reduced height to account for header height */
    text-align: center;
    padding-top: 60px;
    /* space for header */
}


#canvas-container {
    position: relative;
}

#drawing-canvas {
    position: absolute;
    top: 0;
    left: 0;
    border: 2px solid black;
}

.styled-button {
    width: 150px;
    height: 50px;
    padding: 10px 20px;
    border-radius: 5px;
    border: none;
    background-color: #403368;
    color: white;
    font-family: var(--font-family-judson);
    cursor: pointer;
    transition: background-color 0.3s;
}

.styled-button:hover {
    background-color: #594a8d;
}

.button-container {
    display: inline-flex;
    align-items: center;
    /* If you want to vertically center align the elements */
    justify-content: space-around;
    width: 50%;
    /* Adjust this value based on the desired spacing */
    margin-top: 20px;
}

.button-container>div {
    width: 1000px;
    /* Setting the width to 1000px */
    text-align: center;
    /* Centers the content inside the div */
}

.save-buttons {
    display: flex;
    justify-content: center;
    gap: 10px;
    margin-top: 20px;
}


#delete_btn {
    margin-top: 20px;
}


.logo-section {
    display: flex;
    margin-top: 20px;
    justify-content: center;
}

.frame-2 {
    display: flex;
    flex-direction: column;
    /* Stack children vertically */
    align-items: center;
    /* Center children horizontally */
}

.company-name {
    color: #403368;
    font-family: var(--font-family-judson);
    font-size: var(--font-size-l);
    font-weight: 400;
    letter-spacing: 0;
    line-height: normal;
    position: relative;
    white-space: nowrap;
    text-align: center;
    /* To center the text within the company-name div */
    width: 515px;
    /* If this causes issues with centering, you may want to adjust or remove */
}

.select_color {
    margin: 20px;
}


.output_list {
    /* margin-top: 10px; */
    border: 1px solid #403368;
    /* This adds a black border. Adjust the color and thickness as needed. */
    padding: 10px;
    /* Adding some padding to give space between the border and the inner content. Adjust as needed. */
}

#bbox_list_container {
    /* display: none; */
    /* This hides the element */
    min-width: 500px;
    border: 1px solid #4033683b;
    padding: 10px;
    margin-top: 20px;
}

#coordsDisplay {
    font-family: var(--font-family-judson);
    color: #403368;
}

#bboxDisplay {
    font-family: var(--font-family-judson);
    color: #403368;
}

.select_color {
    margin-right: 10px;
    font-family: var(--font-family-judson);
}