/* Assuming you have some CSS file (like App.css) where you define your styles */

.App {
  display: flex;
  flex-direction: column;
}

.main-content-wrapper {
  flex: 1;
  flex-direction: column;
}

.footer-content {
  /* margin-top: 5%; */
  width: 100%;
}