:root { 
  --black: #000000;
  --headercolorsolid: #717f9c;
  --light-gray: #eeeeee;
  --title-font-color: #717f9c;
 
  --font-size-l: 70px;
  --font-size-m: 20px;
  --font-size-xl: 90px;
  --font-size-xxl: 96px;
 
  --font-family-inter: "Inter", Helvetica;
  --font-family-judson: "Judson", Helvetica;
  --font-family-saira_condensed: "Saira Condensed", Helvetica;
}
