body,
html {
  min-height: 100%;
  margin: 0;
}

.centered-container {
  min-height: 100%;
  display: flex;
  flex-direction: column;
  /* makes the footer stick to the bottom */
}

.rectangle-2 {
  margin-top: auto;
  /* pushes footer to the bottom */
}


/* For mobile screens */
@media only screen and (max-width: 768px) {
  .main-content .header-section {
    flex-direction: column;
    padding: 15px;
  }

  .main-content .logo-section {
    flex-direction: column;
    width: 100%;
    align-items: center;
    padding: 10px;
  }

  .main-content .company-name {
    text-align: center;
    width: 100%;
  }

  .modal-content {
    width: 90%;
    /* Adjust modal width for smaller screens */
  }
}



/* screen - desktop-1 */

.main-content {
  align-items: center;
  background-color: var(--light-gray);
  border: 1px none;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  width: auto;
  min-height: 100vh;
  /* overflow-y: auto; */
  /* background-color: #4CAF50; */
}


/* .main-content .header-section {
  align-items: center;
  background-color: var(--light-gray);
  display: flex;
  height: 90px;
  min-width: 1440px;
  padding: 0 160px;
} */

/* .main-content .logo-section {
  align-items: center;
  display: flex;
  height: 45px;
  justify-content: space-between;
  position: relative;
  width: 1097px;
} */

/* .main-content .frame-2 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 21px;
  margin-bottom: -19.00px;
  margin-top: -19.00px;
  position: relative;
} */

/* .main-content .company-logo {
  height: 83px;
  position: relative;
  width: 74px;
} */

.company-name {
  color: #403368;
  font-family: var(--font-family-judson);
  font-size: var(--font-size-l);
  font-weight: 400;
  height: 57px;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1.00px;
  position: relative;
  white-space: nowrap;
  width: 515px;
}

.main-content .frame {
  flex: 0 0 auto;
  position: relative;
}

.main-content .frame-10 {
  align-items: flex-start;
  display: inline-flex;
  flex-direction: column;
  gap: 10px;
  margin-right: 1.0px;
  position: relative;
}

.main-content .rectangle-1 {
  height: 718px;
  margin-bottom: -8.00px;
}

.main-content .discover-deeper-whe {
  color: transparent;
  font-family: var(--font-family-saira_condensed);
  font-size: var(--font-size-xl);
  font-weight: 700;
  left: 157px;
  letter-spacing: 0;
  line-height: 88px;
  position: absolute;
  top: 95px;
  width: 747px;
}

.main-content .discover-deeper-whe_line2 {
  color: transparent;
  font-family: var(--font-family-saira_condensed);
  font-size: var(--font-size-xl);
  font-weight: 700;
  left: 157px;
  letter-spacing: 0;
  line-height: 88px;
  position: absolute;
  /* top: 51px; */
  top: 180px;
  /* width: 747px; */
  width: 600px;
}

.main-content .span0 {
  color: #403368;
}

.main-content .span1 {
  color: #ffffff;
}

.main-content .span2 {
  color: #dedede;
}

.main-content .frame-6 {
  align-items: flex-start;
  display: inline-flex;
  flex-direction: column;
  gap: 9px;
  margin-right: 298.2px;
  margin-top: 14px;
  position: relative;
}

.main-content .about-scholarly-ai-docs {
  height: 45.67px;
  margin-left: -3.05px;
  margin-bottom: 1rem;
  position: relative;
  width: auto;
  color: #403368;
  font-family: var(--font-family-inter);
  font-size: 60px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 33.9px;
  /* position: relative; */
  /* width: 821.8px; */
}

.main-content .welcome-to-scholarly {
  color: var(--headercolorsolid);
  font-family: var(--font-family-inter);
  font-size: var(--font-size-m);
  font-weight: 400;
  height: 413.34px;
  letter-spacing: 0;
  line-height: 33.9px;
  position: relative;
  width: 821.8px;
}

/* .main-content  */
.frame-12 {
  align-items: flex-start;
  display: inline-flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 362px;
  position: relative;
}

/* .main-content  */
/* .rectangle-2 {
  background-color: var(--black);
  height: 277px;
} */

.rectangle-2 {
  background-color: var(--black);
  height: 277px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.frame-11 {
  position: relative;
  left: 0;
  top: 0;
  align-items: center;
  display: flex;
  justify-content: space-between;
}

/* .main-content  */
.coming-soon {
  color: #ffffff;
  font-family: var(--font-family-saira_condensed);
  font-size: var(--font-size-xxl);
  font-weight: 700;
  height: 84px;
  letter-spacing: 0;
  line-height: 88px;
  margin-bottom: -2.50px;
  margin-top: -4.50px;
  margin-right: 100px;
  position: relative;
  white-space: nowrap;
  width: 489px;
}

/* .main-content  */
.rectangle {
  position: relative;
  /* width: block; */
}


#cognito-login-btn {
  background-color: #4CAF50;
  color: white;
  padding: 10px 20px;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s;
  text-align: center;
  display: inline-block;
  font-size: 18px;
  margin: 4px 2px;
  border-radius: 4px;
  text-decoration: none;
}

#cognito-login-btn:hover {
  background-color: #45a049;
  /* Slightly darker shade when hovered */
}

.user-login-soon {
  font-family: var(--font-family-saira_condensed);
  font-size: var(--font-size-l);
  font-weight: normal;
  margin-bottom: 10%;
  margin-bottom: 10%;
  color: #66e96a;

}

/* The Modal */
.modal {
  display: none;
  /* Hidden by default */
  position: fixed;
  /* Stay in place */
  z-index: 1;
  /* Sit on top */
  left: 0;
  top: 0;
  width: 100%;
  /* Full width */
  height: 100%;
  /* Full height */
  overflow: auto;
  /* Enable scroll if needed */
  /* background-color: rgba(0, 0, 0, 0.4); */
  /* Black with a bit of transparency */
  background-color: rgba(64, 51, 104, 0.7);
  /* Slight transparent background for overlay */
}

/* Modal Content */
.modal-content {
  background-color: #fefefe;
  margin: 15% auto;
  padding: 20px;
  border: 1px solid #888;
  width: 40%;
  max-width: 500px;
  /* Could be more or less, depending on screen size */
  border-radius: 15px;
  /* Add rounded corners */
  background: linear-gradient(135deg, #8E77D8, #A093EB);
  /* Gradient background from your main design */
  color: #fefefe;
  /* Make text color consistent */
  text-align: center;
}

/* Input Fields */
.modal-content input[type="text"],
.modal-content input[type="password"] {
  width: 100%;
  padding: 12px 20px;
  margin: 8px 0;
  display: inline-block;
  border: 1px solid #ccc;
  box-sizing: border-box;
  border-radius: 5px;
  /* Add rounded corners */
}

.modal-content input[type="submit"] {
  background-color: #4CAF50;
  color: white;
  padding: 14px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  width: 100%;
}

.modal-content input[type="submit"]:hover {
  background-color: #45a049;
}


/* The Close Button */
.close {
  color: #fefefe;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}

.modal-content h2 {
  font-family: var(--font-family-saira_condensed);
  font-size: var(--font-size-xxl);
  /* font-weight: 200; */
  font-weight: 400;
  text-align: center;
  margin-bottom: 8%;
}

.modal-content label {
  font-family: var(--font-family-inter);
  font-size: var(--font-size-m);
  font-weight: 400;
}

#modalForm {
  display: inline-block;
  justify-content: space-around;
  min-width: 50%;
  font-family: var(--font-family-saira_condensed);
  font-size: var(--font-size-m);
  letter-spacing: 3px;
}



#modal-input {
  font-size: 18px;
}

#modalForm p {
  font-size: 50px;
  margin-bottom: 5%;
}

#modalForm .modal-btn {
  margin-left: 25%;
  margin-right: 25%;
  /* max-width: 25%; */
}

.modal-submit {
  font-size: 18px;
  margin-top: 10%;
}

.message {

  font-weight: normal;
  margin-bottom: 10%;
  margin-bottom: 10%;
  color: #e74444;
}

.modal-menu {
  display: inline-flex;
  align-items: center;
  justify-content: space-around;
  font-family: var(--font-family-saira_condensed);
  font-size: var(--font-size-m);
}

.modal-separator {
  display: inline-block;
  height: 20px;
  /* or whatever height you want */
  width: 1px;
  background-color: #40336891;
  /* or any other color */
  margin: 0 10px;
  /* some horizontal margin for spacing */
}

.modal-menu {
  margin-bottom: 5%;
}



@keyframes gradientAnimation {
  0% {
    background-position: 0% 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0% 50%;
  }
}


.user-login-soon.modal-content {
  /* background: linear-gradient(45deg, #f06, #9f6, rgb(0, 174, 255)); */
  background: linear-gradient(45deg, rgb(233, 44, 119), #8E77D8, #c9c3eb);
  /* Example gradient colors */
  background-size: 200% 200%;
  /* Needed for gradient animation */
  animation: gradientAnimation 5s infinite;
  /* Apply the animation */

  /* Additional styling */
  padding: 20px;
  color: white;
  text-align: center;
  min-width: 50%;
}


#main-login-btn {
  background-color: #4CAF50;
  color: white;
  padding: 10px 20px;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s;
  text-align: center;
  display: inline-block;
  font-size: 18px;
  margin: 4px 2px;
  border-radius: 4px;
  text-decoration: none;
}

#main-login-btn:hover {
  background-color: #45a049;
}

.main-headers {
  display: flex;
  justify-content: space-between;

  align-items: center;
  margin-top: 5px;
  margin-bottom: 5px;
  min-width: fit-content;
  max-width: 75%;
  margin-left: 10%;
  margin-right: 10%;
}

.main-headers>div:first-child {
  display: flex;
  gap: 10px;
  justify-content: space-around;
}

.header-menu {
  display: inline-flex;
  /* align-items: center; */
  justify-content: space-between;
  font-size: 18px;
}

.header-menu-separator {
  height: 20px;
  width: 1px;
  background-color: #40336891;
  margin: 0 20px;
}

.logoutBtn-with-name {
  display: inline;
  color: #7d71a0;
  font-size: 18px;
  /* text-align: right; */
}

.display-inline {
  display: flex;
  align-items: center;
}

.display-inline>label {
  width: 100%;
  text-align: right;
  padding-right: 5%;
}