.left-panel {
    width: 30%;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.right-panel {
    width: 100%;
    display: block;
    justify-content: left;
    padding-left: 5%;
    overflow-y: scroll;
    overflow-x: auto;
    /* overflow: hidden; */
}


/* Adjustable widgets */
.upload-widget {
    padding: 15px;
    border: 1px solid #ccc;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    position: relative;
    width: 100%;
    min-height: 15%;
    /* border: 1px solid black; */
    overflow: hidden;
}

.options-widget {
    padding: 15px;
    border: 1px solid #ccc;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    position: relative;
    width: 100%;
    min-height: 15%;
    /* border: 1px solid black; */
    overflow: hidden;
}

.resizer {
    width: 100%;
    height: 5px;
    cursor: ns-resize;
    background: rgba(0, 0, 0, 0.1);
    user-select: none;
    position: absolute;
    bottom: 0;
}

.resizer-canvas {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 10px;
    height: 10px;
    background: red;
    cursor: se-resize;
}


/* AdjustableCanvas */
.canvas-container {
    position: relative;
    width: 200px;
    /* initial width */
    height: 200px;
    /* initial height */
    border: 1px solid black;
}

#myCanvas {
    width: 100%;
    height: 100%;
    display: block;
}


/* ResizableWidget */
.widget-page {

    border-top: 1px solid #7e759b5b;
    height: calc(100vh - 120px);
    /* Adjusting for header and footer */
    display: flex;
    justify-content: start;
    /* align-items: start; */
    padding-top: 10px;

}

.left-widgets {
    position: relative;
    /* border: 1px solid red; */
    /* display: flex; */
    min-width: 1%;
    position: relative;
    /* width: 150px; */
    /* Initial width */
    height: 100%;
    /* Will span the full height of its parent */
    /* border-right: 1px solid red; */
    float: left;
    /* scrollbar */
    overflow-y: scroll;
    overflow-x: auto;
    /* Box shadow for sunken effect */
    box-shadow: inset 2px 2px 5px #555, inset -2px -2px 5px #aaa;
    /* Gradient for more depth */
    /* background: linear-gradient(-45deg, #71809c48, #c9c3eb41); */
    background: #c9c3eb41;
}

/* Complimentary colors to   #8E77D8, #A093EB*/
/* Gold/Yellow Tones: #FAD02E #F7E464  */
/* Teal/Greenish-Blue Tones: #40A8C4 #2CB299  */
/* Soft Peach/Pink Tones: #FFD5C2 #FFC1A1  */
/* Neutrals: #ECECEC (Light Gray) #333333 (Almost Black)  */

.widget-generic {
    position: relative;
    min-width: 1%;
    /* Initial width */
    height: 100%;
    /* Will span the full height of its parent */
    /* border-right: 1px solid black; */
    float: left;
    /* Allow content to flow to its right */
}

.resizer-generic {
    width: 5px;
    height: 100%;
    /* Full height of the widget */
    position: absolute;
    right: 0;
    top: 0;
    background: rgba(0, 0, 0, 0.1);
    cursor: ew-resize;
    user-select: none;
}

.left-widgets,
.widget-generic {
    float: none;
}

/* Custom scrollbar styles */
/* .left-widgets::-webkit-scrollbar {
    width: 10px;

}

.left-widgets::-webkit-scrollbar-track {
    background: #f1f1f1;
}

.left-widgets::-webkit-scrollbar-thumb {
    background: #c9c3eb8a;
}

.left-widgets::-webkit-scrollbar-thumb:hover {
    background: #c9c3ebb2;
}
 */


.left-widgets::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    background-color: #c9c3ebb2;
}

.left-widgets::-webkit-scrollbar {
    width: 12px;
    background-color: #F5F5F5;
}

.left-widgets::-webkit-scrollbar-thumb {
    /* background-image: linear-gradient(180deg, rgba(112, 104, 138, 0.651), #8E77D8); */
    border-radius: 10px;
    box-shadow: inset 2px 2px 5px 0 rgba(255, 255, 255, 0.5);
    /* adjusted rgba() value */
    background-color: #7e759ba8;
}




.right-panel::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    background-color: #c9c3ebb2;
}

.right-panel::-webkit-scrollbar {
    width: 12px;
    background-color: #F5F5F5;
}

.right-panel::-webkit-scrollbar-thumb {
    /* background-image: linear-gradient(180deg, rgba(112, 104, 138, 0.651), #8E77D8); */
    border-radius: 10px;
    box-shadow: inset 2px 2px 5px 0 rgba(255, 255, 255, 0.5);
    /* adjusted rgba() value */
    background-color: #7e759ba8;
}